import { useMutation } from '@apollo/client';
import { Alert, Form, Input } from 'antd';
import { React, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LOCAL_STORAGE_KEY, MESSAGE, ROUTES } from '../../common/constants';
import {
  formItemProps,
  formValidatorRules,
  removeItem,
  setItem,
} from '../../common/utils';
import CommonButton from '../../components/common/button/Button';
import useRouter from '../../hooks/useRouter';
import { SEND_OTP } from './graphql/mutations';

const { required, whitespace, email } = formValidatorRules;

export default function AuthForm({ allowName = false }) {
  const [form] = Form.useForm();
  const { navigate } = useRouter();
  const { state } = useLocation();
  const [alert, setAlert] = useState(false);

  const initialValues = { email: state?.email };
  const [sendOtp] = useMutation(SEND_OTP, {
    onError() {},
  });

  const onFinish = async (values) => {
    const sendOtpVariables = {
      email: values?.email,
    };
    if (allowName) {
      sendOtpVariables.fullName = values?.fullName;
      sendOtpVariables.isNewUser = true;
    }
    sendOtp({
      variables: {
        data: sendOtpVariables,
      },
      onCompleted() {
        navigate(`${ROUTES?.VERIFY_OTP}`, {
          state: {
            email: values?.email,
          },
        });
        setItem(LOCAL_STORAGE_KEY?.CURRENT_EMAIL, values?.email);
        removeItem(LOCAL_STORAGE_KEY.FUTURE_TIME);
      },
    });
  };

  useEffect(() => {
    if (state?.emailVerificationError) {
      setAlert(true);
      navigate(ROUTES?.LOGIN, {
        state: {
          ...state,
          emailVerificationError: false,
        },
      });
    }
  }, []);
  return (
    <>
      {alert && (
        <Alert
          className="login-alert"
          message="Email verification token expired!"
          closable
          type="error"
          onClose={() => setAlert(false)}
        />
      )}
      <div className="form-wrapper">
        <div className="text-left mb-24">
          <h4 className="form-wrapper-headline">
            Welcome to Logicwind Knowledge Base
          </h4>
        </div>
        <Form
          name="auth-form"
          initialValues={initialValues}
          onFinish={onFinish}
          size="large"
          form={form}
          layout="vertical"
          className="login-form"
        >
          <div className="form-fields-wrapper">
            {allowName && (
              <Form.Item
                name="fullName"
                label="Full Name"
                className="mb-16"
                rules={[whitespace, { required, message: MESSAGE.required }]}
              >
                <Input placeholder="Enter your full name" autoFocus />
              </Form.Item>
            )}
            <Form.Item
              name="email"
              label="Email Address"
              rules={[{ required, message: MESSAGE.required }, email]}
              normalize={formItemProps?.normalize}
              className="mb-48"
            >
              <Input
                placeholder="Enter your email address"
                disabled={allowName}
                className="email-address"
                autoFocus
              />
            </Form.Item>
          </div>
          <Form.Item shouldUpdate className="full-width mt-32 mb-0">
            {() => (
              <CommonButton
                type="primary"
                block
                htmlType="submit"
                shape="round"
                disabled={
                  (!form.isFieldTouched('email') &&
                    !form.isFieldTouched('fullName')) ||
                  !!form
                    ?.getFieldsError()
                    ?.filter(({ errors }) => errors?.length)?.length
                }
              >
                Send OTP
              </CommonButton>
            )}
          </Form.Item>
        </Form>
      </div>
    </>
  );
}
