import { Carousel, Modal, Typography } from 'antd';
import { map } from 'lodash';
import React, { useState } from 'react';
import {
  CloseIcon,
  RedirectIcon,
  SlickNext,
  SlickPrev,
} from '../../../assets/svg';
import { ASSET_TYPE, validAssetTypes } from '../../../common/constants';
import { getPageNumberAndParagraphNumber } from '../../../common/utils';
import { messageContext } from '../../AppContextHolder';
import AssetImage from '../AssetImage';
import Player from '../player/Player';
import SlickCardSkeleton from '../skeleton';

const { Paragraph } = Typography;

export default function AssetsSlider({ data }) {
  const [isModal, setIsModal] = useState(false);
  const [currentData, setCurrentData] = useState(null);

  const NextButton = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <SlickNext />
      </div>
    );
  };

  const PrevButton = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <SlickPrev />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    nextArrow: <NextButton />,
    prevArrow: <PrevButton />,
    responsive: [
      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 645,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 526,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleCardClick = (event, obj = null) => {
    if (obj?.link) {
      const assetType = obj?.type?.toUpperCase();
      if (validAssetTypes?.includes(assetType)) {
        if (assetType !== ASSET_TYPE.VIDEO) {
          setCurrentData({
            ...obj,
            link: `${obj?.link}&t=${obj?.meta?.startTime || 0}s`,
          });
          setIsModal(true);
          event?.preventDefault();
        } else {
          // eslint-disable-next-line no-undef
          window.open(obj?.link, '_blank');
        }
      } else {
        return false;
      }
    } else {
      messageContext?.error('No URL found.');
    }
  };

  const SlickCard = ({ data: value }) => {
    const { page } = getPageNumberAndParagraphNumber(value?.meta?.description);
    return (
      <a
        href={`${value?.link}#page=${page}`}
        onClick={(event) => handleCardClick(event, value)}
        target="_blank"
        rel="noreferrer"
      >
        <div className="asset-card">
          <AssetImage
            imageUrl={value?.headerImageUrl}
            type={value?.type}
            width={220}
            meta={value?.meta}
          />
          <div className="asset-meta">
            <Paragraph className="asset-title" ellipsis={{ rows: 3 }}>
              {value?.title}
            </Paragraph>
            <Paragraph className="asset-link">
              {value?.meta?.description}
            </Paragraph>
          </div>
        </div>
      </a>
    );
  };

  const handleRedirection = (link) => {
    setIsModal(false);
    // eslint-disable-next-line no-undef
    window.open(link, '_blank');
  };

  const handleCancel = () => {
    setIsModal(false);
  };

  // Handle asset api loader
  if (data?.assetLoading) {
    return (
      <Carousel {...settings}>
        {map(data?.refIds, (item) => (
          <SlickCardSkeleton key={item?.id} />
        ))}
      </Carousel>
    );
  }
  // Handle asset api error
  if (data?.assetError) {
    return null;
  }

  return (
    <div>
      <Carousel {...settings}>
        {map(data?.refIds, (item) => (
          <SlickCard type="Article" key={item?.id} data={item} />
        ))}
      </Carousel>
      <Modal
        title={
          <div className="player-modal-header">
            <p>{currentData?.title}</p>
            <div className="icon-wrapper">
              <RedirectIcon
                onClick={() => handleRedirection(currentData?.link)}
              />
              <CloseIcon onClick={handleCancel} />
            </div>
          </div>
        }
        open={isModal}
        className="player-modal"
        onCancel={handleCancel}
        width={
          currentData?.type?.toUpperCase() === ASSET_TYPE.PODCAST ? 550 : 1087
        }
        centered
        footer={null}
        closeIcon={null}
        destroyOnClose
      >
        <div className="react-player-wrapper">
          {currentData?.link && (
            <Player
              link={currentData?.link}
              type={currentData?.type?.toUpperCase()}
              startTime={currentData?.meta?.startTime}
            />
          )}
        </div>
      </Modal>
    </div>
  );
}
