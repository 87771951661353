/* eslint-disable no-shadow */
import MDEditor from '@uiw/react-md-editor';
import { Divider, Skeleton, Typography, message } from 'antd';
import { map } from 'lodash';
import React, { useContext, useMemo } from 'react';
import { AppContext } from '../../AppContext';
import { ChevronUp, CopyIcon } from '../../assets/svg';
import { ACTION_TYPES } from '../../common/constants';
import { getPageNumberAndParagraphNumber } from '../../common/utils';
import AssetsSlider from '../../components/common/slider/AssetsSlider';
import KeywordBasedResult from './component/KeywordBasedResult';

const { Text } = Typography;

export default function Chat() {
  const {
    dispatch,
    state: { animation, chats, search },
  } = useContext(AppContext);

  const ChatRight = ({ title = '', id }) => (
    <div className="chat-right" id={`chat-${id}`}>
      <p className="text-search">{title}</p>
    </div>
  );

  const SuggestionsList = ({ list = [] }) => (
    <div className="suggestion-section">
      <p className="suggestion-title">You may also want to ask</p>
      <div className="suggestion-list">
        {map(list, (data, index) => (
          <div className="suggestion-item" key={`suggestion-${index}`}>
            <p className="text-question">{data?.title}</p>
            <ChevronUp
              width={24}
              height={24}
              className="suggestion-icon pointer"
              onClick={() => {
                dispatch({
                  type: ACTION_TYPES.UPDATE_SEARCH_QUERY,
                  data: data?.title,
                });
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );

  const ChatLeft = ({ data }) => {
    if (data?.keywordLoading) {
      return (
        <Skeleton active paragraph={{ rows: 4 }} className="chat-skeleton" />
      );
    }

    const DynamicLinks = (data) => {
      const updateLinks = (data) =>
        data?.resText?.replace(/<a[^>]*>(\d+)<\/a>/g, (_match, children) => {
          const dataObj = data?.refIds?.[+children - 1];
          const { page } = getPageNumberAndParagraphNumber(
            dataObj?.meta?.description,
          ); // Extract the page number correctly
          return `<a href="${dataObj?.link}#page=${page}" class='text-link' target='_blank'>${children}</a>`;
        });
      return updateLinks(data);
    };

    return (
      <>
        <KeywordBasedResult data={data?.keywordSearchedData} />
        {data?.loading ? (
          <Skeleton active paragraph={{ rows: 4 }} className="chat-skeleton" />
        ) : (
          <>
            {data?.error ? (
              <div className="chat-left result-error" id="chat-left">
                <p className="text-result-error">
                  The search results do not provide any direct information about
                  the above query.
                </p>
              </div>
            ) : (
              <>
                <div>
                  <Divider dashed />
                </div>
                <p className="body-xl ai-generated-keyword-result">
                  AI generated results:
                </p>
                {data?.resText && (
                  <div className="content-box">
                    <MDEditor.Markdown source={DynamicLinks(data) ?? ''} />
                  </div>
                )}
                {data?.refIds?.length > 0 && (
                  <div>
                    <AssetsSlider data={data} />
                  </div>
                )}
                {data?.resText && (
                  <Text
                    copyable={{
                      text: data.resTextPlain,
                      icon: [
                        <CopyIcon key="copy-icon" />,
                        <CopyIcon key="copied-icon" />,
                      ],
                      onCopy: () => {
                        message?.config({ prefixCls: 'ant-copy' });
                        message?.open({
                          content: 'Copied to clipboard',
                          onClick: () => {
                            message?.destroy();
                          },
                        });
                      },
                    }}
                  />
                )}
                {data?.suggestions?.length > 0 && (
                  <>
                    <Divider dashed />
                    <SuggestionsList list={data.suggestions} />
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  };

  const memoizedChatLeftArray = useMemo(
    () => map(chats, (data) => <ChatLeft key={data?.id} data={data} />),
    [chats, search],
  );

  return (
    <div className={`chat-screen section ${!animation?.mode ? 'active' : ''}`}>
      <div className="chat-box-wrapper">
        {map(memoizedChatLeftArray, (ChatLeft, index) => {
          const {
            props: { data },
          } = ChatLeft;
          return (
            <>
              <ChatRight title={data?.query} id={index} />
              <div className="chat-left" id={`chat-left-${index}`}>
                {ChatLeft}
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}
