import dayjs from 'dayjs';

/* COMPANY INFO */
export const COMPANY_INFO = {
  NAME: 'Logicwind',
  COOKIE_EXPIRY: 525600, // 365 days in minutes,
  FOOTER_TEXT: `© Logicwind ${dayjs()?.format('YYYY')}. All Rights Reserved.`,
};
/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  LOGOUT: '/logout',
  SIGNUP: '/signup',
  LOGIN: '/login',
  FORGET_PASSWORD: '/forgot-password',
  RESET: '/reset-password',
  VERIFY: '/verify',
  AUTHENTICATION: '/authentication',
  CONTENTS: '/contents',
  PROFILE: '/profile',
  VERIFY_OTP: '/verify-otp',
  VERIFY_EMAIL: '/verify-email',
};

/*  Modules */
export const MODULES = {
  DASHBOARD: 'Dashboard',
  CONTENT: 'Contents',
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER = 'USER';

export const ROLES = {
  SUPER_ADMIN: 'Super admin',
  ADMIN: 'Admin',
};

export const ROLE_KEYS = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
};

export const LOCAL_STORAGE_KEY = {
  CURRENT_EMAIL: 'CURRENT_EMAIL',
  FUTURE_TIME: 'FUTURE_TIME',
};

/* Date and time */
export const defaultDateFormat = 'MM/DD/YYYY';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL:
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL:
    /^(([^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+(\.[^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  COUNTRY_CODE_PHONE: /^[0-9]{7,12}$/,
  VECTOR_ID: /(?:\(|\[|\{)vec-[A-Za-z0-9_-]+(?:\)|\]|\})/g,
  VECTOR_STRING: /vec-[a-zA-Z0-9_-]+/g,
  // eslint-disable-next-line no-useless-escape
  VECTOR_IDENTIFIER: /[\(\)\[\]\{\}]/g,
};
export const ERROR_PAGE_TITLE = 'Oops! An error occurred!';
export const ERROR_PAGE_SUBTITLE =
  'Something is broken. Please let us know what you were doing when this error occurred. We will fix it as soon as possible. Sorry for any inconvenience caused.';

export const ACTION_TYPES = {
  UPDATE_ANIMATION: 'UPDATE_ANIMATION',
  TRIGGER_SCROLL: 'TRIGGER_SCROLL',
  UPDATE_SEARCH_QUERY: 'UPDATE_SEARCH_QUERY',
  UPDATE_CHATS: 'UPDATE_CHATS',
  SET_TOKEN: 'SET_TOKEN',
  SET_REFRESH_TOKEN: 'SET_REFRESH_TOKEN',
  SET_AUTHENTICATED: 'SET_AUTHENTICATED',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  LOGOUT: 'LOGOUT',
};

export const ASSET_TYPE = {
  ARTICLE: 'ARTICLE',
  VIDEO: 'VIDEO',
  PODCAST: 'PODCAST',
  PDF: 'PDF',
  WEBSITE: 'WEBSITE',
  AUDIO: 'AUDIO',
};

export const validAssetTypes = [
  ASSET_TYPE.VIDEO,
  ASSET_TYPE.PODCAST,
  ASSET_TYPE.AUDIO,
];

export const API_ENDPOINTS = {
  getAIData: '/generate_response',
  getKeywordAIData: '/keyword_search',
};

export const MESSAGE = {
  required: 'This field is required.',
};
