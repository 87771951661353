import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { LOCAL_STORAGE_KEY } from '../common/constants';
import { getItem, setItem } from '../common/utils';

const TIME_DIIFRENCE = 999; // 999 millisecound
const OTP_RESEND_DELAY = 10; // 10 minutes

export default function useOtpTimer() {
  const [remainingTime, setRemainingTime] = useState(0);

  const startCountdown = (futureTime) => {
    const interval = setInterval(() => {
      const currentTime = dayjs();
      setRemainingTime(() => {
        const difference = futureTime.diff(currentTime);
        if (difference <= TIME_DIIFRENCE) {
          clearInterval(interval);
        }
        return difference;
      });
    }, 1000);

    return () => clearInterval(interval);
  };

  useEffect(() => {
    const getLocalStorageFutureTime =
      getItem(LOCAL_STORAGE_KEY.FUTURE_TIME) ?? null;
    let futureTime = '';
    if (getLocalStorageFutureTime) {
      futureTime = dayjs(getLocalStorageFutureTime);
    } else {
      futureTime = dayjs().add(OTP_RESEND_DELAY, 'minute');
      setItem(LOCAL_STORAGE_KEY.FUTURE_TIME, futureTime);
    }
    startCountdown(futureTime);
  }, []);

  const startTimer = () => {
    const futureTime = dayjs().add(OTP_RESEND_DELAY, 'minute');
    setItem(LOCAL_STORAGE_KEY.FUTURE_TIME, futureTime);
    startCountdown(futureTime);
  };

  const formatTime = (ms) => {
    const minutes = Math.floor(ms / 60000);
    const seconds = Math.floor((ms % 60000) / 1000);
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  return {
    remainingTime,
    formattedTime: formatTime(remainingTime),
    startTimer,
    canResend: remainingTime <= TIME_DIIFRENCE,
  };
}
