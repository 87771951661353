import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { REGEX } from './constants';

dayjs.extend(duration);

export const formValidatorRules = {
  required: {
    required: true,
    message: 'Required',
  },
  whitespace: {
    whitespace: true,
    message: 'Invalid input.',
  },
  email: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.EMAIL?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Invalid email.');
      }
      return Promise?.resolve();
    },
  }),
  name: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.NAME?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Please enter valid name');
      }
      return Promise?.resolve();
    },
  }),
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!Number(value) || !REGEX?.NUMBER?.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid number');
      }
      return Promise?.resolve();
    },
  }),
};

export const formItemProps = { normalize: (value) => value?.trim() };

// create cookie
export const setCookie = (name, value, minutes) => {
  let expires = '';
  if (minutes) {
    const date = new Date();
    date?.setTime(date?.getTime() + minutes * 60 * 1000);
    expires = `; expires=${date?.toGMTString()}`;
  }
  // eslint-disable-next-line no-undef
  document.cookie = `${name}=${value}${expires}; path=/`;
};

// retrieve cookie
export const getCookie = (cookieName) => {
  const name = `${cookieName}=`;
  let returnCookie = '';
  // eslint-disable-next-line no-undef
  const decodedCookie = decodeURIComponent(document?.cookie);
  const cookieArray = decodedCookie?.split(';');
  if (cookieArray?.length > 0) {
    // eslint-disable-next-line array-callback-return
    cookieArray?.map((item) => {
      let cookie = item;
      while (cookie?.charAt(0) === ' ') {
        cookie = cookie?.substring(1);
      }
      if (cookie?.indexOf(name) === 0) {
        returnCookie = cookie?.substring(name?.length, cookie?.length);
      }
    });
    return returnCookie;
  }
};

// force expired cookie or delete
export const deleteCookie = (name) => {
  // eslint-disable-next-line no-undef
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

// function to get time difference
export const getTimeDifference = (startTime, endTime) => {
  const start = dayjs(startTime);
  const end = dayjs(endTime);

  const timeDiff = dayjs?.duration(end?.diff(start));

  const hours = timeDiff?.hours();
  const minutes = timeDiff?.minutes();
  const seconds = timeDiff?.seconds();

  let formattedTime = '';

  if (hours > 0) {
    formattedTime += `${hours}h `;
  }
  if (minutes > 0) {
    formattedTime += `${minutes}m `;
  }
  if (seconds > 0) {
    formattedTime += `${seconds}s`;
  }
  return formattedTime;
};

export const convertSecondsToTime = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  let formattedTime = '';

  if (hours > 0) {
    formattedTime += `${hours}h `;
  }
  if (minutes > 0) {
    formattedTime += `${minutes}m `;
  }
  if (seconds > 0) {
    formattedTime += `${seconds}s`;
  }
  return formattedTime;
};

export const numberToPercentage = (number, total) => (number / total) * 100;

export const removeLastWordFromArray = (array) => {
  if (array?.length === 0) {
    return array;
  }
  const newArray = array?.slice(0, array?.length - 1)?.join(' ');

  return newArray;
};

export const isFormDisabled = (formInstance) =>
  !formInstance?.isFieldsTouched(true) ||
  formInstance?.getFieldsError()?.filter(({ errors }) => errors?.length)
    ?.length > 0;

export const getPageNumberAndParagraphNumber = (data) => {
  const match = data?.match(/Page (\d+), Paragraph (\d+)/);
  if (match) {
    const page = parseInt(match[1], 10); // Extract Page Number
    const paragraph = parseInt(match[2], 10); // Extract Paragraph Number
    return { page, paragraph };
  }
  return { page: 1, paragraph: 1 };
};

export const setItem = (name, value) => {
  // eslint-disable-next-line no-undef
  localStorage?.setItem(name, value);
};

export const getItem = (name) => {
  // eslint-disable-next-line no-undef
  const value = localStorage?.getItem(name);
  return value;
};

export const removeItem = (name) => {
  // eslint-disable-next-line no-undef
  localStorage.removeItem(name);
};
