import { useMutation } from '@apollo/client';
import { Form, Input } from 'antd';
import { React, useContext, useState } from 'react';
import OTPInput from 'react-otp-input';
import { AppContext } from '../../AppContext';
import { LOCAL_STORAGE_KEY, MESSAGE } from '../../common/constants';
import { formValidatorRules, getItem, removeItem } from '../../common/utils';
import CommonButton from '../../components/common/button/Button';
import useOtpTimer from '../../hooks/useOtpTimer';
import { RESEND_OTP, VERIFY_OTP_INPUT } from './graphql/mutations';

const { required } = formValidatorRules;

export default function OtpForm() {
  const [form] = Form.useForm();
  const { initializeAuth } = useContext(AppContext);
  const [otpError, setOtpError] = useState(false);
  const [otp, setOtp] = useState('');
  const { formattedTime, startTimer, canResend } = useOtpTimer();
  const currentEmail = getItem(LOCAL_STORAGE_KEY.CURRENT_EMAIL) ?? '';
  const [verifyOtp, { loading: verifyOtpLoading }] = useMutation(
    VERIFY_OTP_INPUT,
    {
      onError() {},
    },
  );
  const [resendOtp] = useMutation(RESEND_OTP, {
    onError() {},
  });

  function successCallback(accessToken, userData, refreshToken) {
    initializeAuth(accessToken, userData, refreshToken);
    removeItem(LOCAL_STORAGE_KEY.FUTURE_TIME);
  }

  const onFinish = async (values) => {
    verifyOtp({
      variables: {
        data: {
          email: currentEmail,
          otp: values?.otp,
        },
      },
      onCompleted(response) {
        if (response?.verifyOtp) {
          const {
            accessToken,
            refreshToken,
            data: userData,
          } = response?.verifyOtp;
          if (successCallback) {
            successCallback(accessToken, userData, refreshToken);
          }
        } else {
          form?.resetFields();
        }
      },
      onError() {
        setOtpError(true);
      },
    });
  };

  const handleResendOtp = () => {
    resendOtp({
      variables: {
        where: {
          email: currentEmail,
        },
      },
      onCompleted() {
        form.resetFields();
        // eslint-disable-next-line no-undef
        localStorage.clear();
        startTimer();
      },
      onError() {},
    });
  };

  const handleOtp = (text) => {
    setOtp(text);
  };

  return (
    <div className="form-wrapper">
      <div className="text-left mb-8">
        <h4 className="text-verify">Verify your identity</h4>
        <p className="body-base medium text-neturals4 mb-24">
          {`Enter the code we’ve sent to ${currentEmail ?? ''}`}
        </p>
      </div>
      <Form
        name="otp"
        onFinish={onFinish}
        size="large"
        form={form}
        layout="vertical"
        className="login-form"
        validateTrigger="onChange"
        onValuesChange={() => {
          setOtpError(false);
        }}
      >
        <div className="form-fields-wrapper">
          <Form.Item
            name="otp"
            validateStatus={otpError ? 'error' : ''}
            rules={[{ required, message: MESSAGE.required }]}
            className="otp-form-field mb-12"
          >
            <OTPInput
              value={otp}
              onChange={(e) => handleOtp(e)}
              numInputs={5}
              inputType="tel"
              placeholder="00000"
              renderInput={(props) => <Input {...props} />}
              shouldAutoFocus
            />
          </Form.Item>
          <div className="otp-countdown">
            <p className="text-resend">
              {!canResend ? (
                `Resend OTP in ${formattedTime}` // Use padZero function for formatting
              ) : (
                <span
                  className="text-primary pointer resend-otp-btn"
                  onClick={handleResendOtp}
                >
                  Resend OTP
                </span>
              )}
            </p>
          </div>
        </div>
        <Form.Item className=" full-width mt-48 mb-0">
          <CommonButton
            type="primary"
            block
            htmlType="submit"
            shape="round"
            loading={verifyOtpLoading}
            disabled={
              // eslint-disable-next-line no-console
              form.getFieldValue('otp')?.length !== 5 || otpError
            }
          >
            Confirm OTP
          </CommonButton>
        </Form.Item>
      </Form>
    </div>
  );
}
